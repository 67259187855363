import { useEffect, useContext } from 'react';
import { AnnotationStore } from '../context';
import { notification } from '@allenai/varnish';
import { ApiClient, PaperStatus } from '../services/Client';
import { useHistory } from 'react-router-dom';

export const UndoAnnotation = () => {
    const annotationStore = useContext(AnnotationStore);
    const { pdfAnnotations, setPdfAnnotations } = annotationStore;
    useEffect(() => {
        const handleUndo = (e: KeyboardEvent) => {
            if (e.ctrlKey && e.key === 'z') {
                e.preventDefault();

                setPdfAnnotations(pdfAnnotations.undoAnnotation());
            }
        };

        window.addEventListener('keydown', handleUndo);
        return () => {
            window.removeEventListener('keydown', handleUndo);
        };
    }, [pdfAnnotations, setPdfAnnotations]);

    return null;
};

export const HideAnnotationLabels = () => {
    // Shows or hides the labels of annotations on pressing ctrl.
    // This makes it easier to do detailed annotations.

    const annotationStore = useContext(AnnotationStore);
    const { hideLabels, setHideLabels } = annotationStore;

    // Toggle state on key down.
    useEffect(() => {
        const hideLabelsOnKeyDown = (e: KeyboardEvent) => {
            if (e.ctrlKey && e.key !== 's') {
                setHideLabels(!hideLabels);
            }
        };
        window.addEventListener('keydown', hideLabelsOnKeyDown);
        return () => {
            window.removeEventListener('keydown', hideLabelsOnKeyDown);
        };
    }, [hideLabels, setHideLabels]);

    return null;
};

interface HandleAnnotationSelectionProps {
    setModalVisible: (v: boolean) => void;
}

export const HandleAnnotationSelection = ({ setModalVisible }: HandleAnnotationSelectionProps) => {
    const annotationStore = useContext(AnnotationStore);
    const { selectedAnnotations, setSelectedAnnotations, activeRelationLabel } = annotationStore;
    useEffect(() => {
        const onShiftUp = (e: KeyboardEvent) => {
            const shift = e.keyCode === 16;
            const somethingSelected = selectedAnnotations.length !== 0;
            const hasRelations = activeRelationLabel !== undefined;
            // Shift key up, the user has selected something,
            // and this annotation project has relation labels.
            if (shift && somethingSelected && hasRelations) {
                setModalVisible(true);
            }
            // Otherwise we just clear the selection,
            // if there is something selected, because
            // there are no relations to annotate.
            else if (shift && somethingSelected) {
                setSelectedAnnotations([]);
            }
        };

        window.addEventListener('keyup', onShiftUp);
        return () => {
            window.removeEventListener('keyup', onShiftUp);
        };
    }, [activeRelationLabel, selectedAnnotations, setModalVisible]);

    return null;
};

interface SaveProps {
    sha: string;
    client: ApiClient;
}

export const SaveWithTimeout = ({ sha, client }: SaveProps) => {
    const annotationStore = useContext(AnnotationStore);
    const { pdfAnnotations, setPdfAnnotations } = annotationStore;

    useEffect(() => {
        if (pdfAnnotations.unsavedChanges) {
            const currentTimeout = setTimeout(() => {
                console.log('Saving annotations');
                client
                    .saveAnnotations(sha, pdfAnnotations)
                    .then(() => {
                        setPdfAnnotations(pdfAnnotations.saved());
                    })
                    .catch((err) => {
                        notification.error({
                            message: 'Sorry, something went wrong!',
                            description:
                                'Try re-doing your previous annotation, or contact someone on the Semantic Scholar team.',
                        });
                        console.log('Failed to save annotations: ', err);
                    });
            }, 100);
            return () => clearTimeout(currentTimeout);
        }
    }, [sha, pdfAnnotations]);

    return null;
};

export const SaveBeforeUnload = ({ sha, client }: SaveProps) => {
    const annotationStore = useContext(AnnotationStore);
    const { pdfAnnotations, setPdfAnnotations } = annotationStore;

    useEffect(() => {
        const beforeUnload = (e: BeforeUnloadEvent) => {
            e.preventDefault();

            client
                .saveAnnotations(sha, pdfAnnotations)
                .then(() => {
                    setPdfAnnotations(pdfAnnotations.saved());
                })
                .catch((err) => {
                    notification.error({
                        message: 'Sorry, something went wrong!',
                        description: 'Try re-doing your previous annotation, or contact support.',
                    });
                    console.log('Failed to save annotations: ', err);
                });
        };

        window.addEventListener('beforeunload', beforeUnload);

        return () => {
            window.removeEventListener('beforeunload', beforeUnload);
        };
    }, [sha, pdfAnnotations]);

    return null;
};

interface HandleFinishProps {
    sha: string;
    papers: PaperStatus[];
    client: ApiClient;
}

export const HandleFinish = ({ sha, papers, client }: HandleFinishProps) => {
    const history = useHistory();

    useEffect(() => {
        const handleFinish = () => {
            client
                .markPdfFinished(sha, true)
                .then(() => {
                    notification.success({
                        message: 'Marked document as Finished!',
                    });
                })
                .then(() => {
                    const pendingPapers = papers.filter((p) => !p.finished && p.sha !== sha);

                    setTimeout(() => {
                        const path =
                            pendingPapers.length > 0 ? '/pdf/' + pendingPapers[0].sha : '/';

                        console.log(`Routing to ${path}`);

                        history.push(path);
                    }, 200);
                })
                .catch((err) => {
                    console.log('Failed to mark document as finished: ', err);

                    notification.error({
                        message: 'Sorry, something went wrong!',
                        description: 'Could not mark the document as Finished!',
                    });
                });
        };

        const onFinishKeyboardEvent = (e: KeyboardEvent) => {
            if (e.ctrlKey && e.key === 's') {
                e.preventDefault();
                handleFinish();
            }
        };

        window.addEventListener('keydown', onFinishKeyboardEvent);

        return () => {
            window.removeEventListener('keydown', onFinishKeyboardEvent);
        };
    }, [sha, papers]);

    return null;
};
