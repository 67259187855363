import { useContext } from 'react';
import styled from 'styled-components';

import { DeleteFilled } from '@ant-design/icons';

import { SidebarItem, SidebarItemTitle } from './common';
import { Annotation, AnnotationStore } from '../../context';

import { AnnotationSummary } from '../AnnotationSummary';
import { ApiClient } from '../../services/Client';

interface AnnotationsProps {
    sha: string;
    annotations: Annotation[];
    client: ApiClient;
}

export const Annotations = ({ annotations }: AnnotationsProps) => {
    const annotationStore = useContext(AnnotationStore);

    const onDelete = () => {
        annotationStore.setPdfAnnotations(annotationStore.pdfAnnotations.clear());
    };

    return (
        <SidebarItem>
            <SidebarItemTitle>
                <ActionBar>
                    <DeleteFilled onClick={onDelete} />
                </ActionBar>
                <span>Annotations</span>
            </SidebarItemTitle>
            <AnnotationList>
                {annotations.length === 0 ? (
                    <>No Annotations Yet :(</>
                ) : (
                    <div>
                        {annotations.map((annotation) => (
                            <AnnotationSummary key={annotation.id} annotation={annotation} />
                        ))}
                    </div>
                )}
            </AnnotationList>
        </SidebarItem>
    );
};

const AnnotationList = styled.div`
    margin-top: 10px;
`;

const ActionBar = styled.div`
    float: right;
`;
