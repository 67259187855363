import { Modal } from '@allenai/varnish';
import styled from 'styled-components';
import React from 'react';

interface HelpModalProps {
    visible: boolean;
    onOk: () => void;
}

export const HelpModal = ({ visible, onOk }: HelpModalProps) => {
    return (
        <Modal
            title="Help"
            width="90%"
            visible={visible}
            onCancel={onOk}
            footer={null}
            keyboard={true}
            style={{ top: '25px' }}>
            <Content>
                <Column>
                    <h5>Hoofd labels</h5>
                    <DefinitionTable>
                        <tr>
                            <th>invoice_number</th>
                            <td>Factuurnummer</td>
                        </tr>
                        <tr>
                            <th>issue_date</th>
                            <td>Factuurdatum</td>
                        </tr>
                        <tr>
                            <th>due_date</th>
                            <td>Vervaldatum</td>
                        </tr>
                        <tr>
                            <th>amount_excluding_tax</th>
                            <td>Bedrag exclusief BTW</td>
                        </tr>
                        <tr>
                            <th>amount_payable</th>
                            <td>Totaalbedrag</td>
                        </tr>
                    </DefinitionTable>

                    <h5>BTW labels</h5>
                    <DefinitionTable>
                        <tr>
                            <th>
                                <div>vat_21_amount</div>
                                <div>vat_9_amount</div>
                                <div>vat_6_amount</div>
                            </th>
                            <td>BTW bedrag</td>
                        </tr>
                        <tr>
                            <th>
                                <div>vat_21_base_amount</div>
                                <div>vat_9_base_amount</div>
                                <div>vat_6_base_amount</div>
                                <div>vat_0_base_amount</div>
                            </th>
                            <td>BTW grondslag</td>
                        </tr>
                        <tr>
                            <th>
                                <div>vat_21_shifted_base_amount</div>
                                <div>vat_9_shifted_base_amount</div>
                                <div>vat_6_shifted_base_amount</div>
                            </th>
                            <td>BTW verlegd grondslag</td>
                        </tr>
                        <tr>
                            <th>vat_total_amount</th>
                            <td>Totaal BTW-bedrag</td>
                        </tr>
                        <tr>
                            <th>vat_shifted</th>
                            <td>"BTW is verlegd" tekst</td>
                        </tr>
                        <tr>
                            <th>charge_amount</th>
                            <td>Toeslag waarover geen BTW wordt berekend</td>
                        </tr>
                        <tr>
                            <th>credit_note</th>
                            <td>
                                <div>"Creditfactuur" tekst</div>
                                <div>Noodzakelijk bij creditfacturen met positieve bedragen</div>
                            </td>
                        </tr>
                    </DefinitionTable>

                    <h5>Leverancier labels</h5>
                    <DefinitionTable>
                        <tr>
                            <th>supplier_name</th>
                            <td>Bedrijfsnaam</td>
                        </tr>
                        <tr>
                            <th>supplier_coc_number</th>
                            <td>KvK-nummer</td>
                        </tr>
                        <tr>
                            <th>supplier_vat_number</th>
                            <td>BTW-nummer</td>
                        </tr>
                        <tr>
                            <th>supplier_iban</th>
                            <td>IBAN</td>
                        </tr>
                    </DefinitionTable>

                    <h5>G-rekening labels</h5>
                    <DefinitionTable>
                        <tr>
                            <th>g_account_iban</th>
                            <td>G-rekening IBAN</td>
                        </tr>
                        <tr>
                            <th>g_account_amount</th>
                            <td>G-rekening bedrag</td>
                        </tr>
                        <tr>
                            <th>g_account_percentage</th>
                            <td>G-rekening percentage</td>
                        </tr>
                    </DefinitionTable>

                    <h5>Referentie labels</h5>
                    <DefinitionTable>
                        <tr>
                            <th>order_ref</th>
                            <td>Ordernummer</td>
                        </tr>
                        <tr>
                            <th>buyer_ref</th>
                            <td>Toegekend door koper</td>
                        </tr>
                        <tr>
                            <th>accounting_cost_ref</th>
                            <td>Kostenplaats</td>
                        </tr>
                        <tr>
                            <th>invoice_ref</th>
                            <td>
                                <div>Factuurreferentie</div>
                                <div>Referentie naar de originele factuur</div>
                            </td>
                        </tr>
                        <tr>
                            <th>project_ref</th>
                            <td>Projectnummer</td>
                        </tr>
                        <tr>
                            <th>contract_ref</th>
                            <td>Contractnummer</td>
                        </tr>
                    </DefinitionTable>

                    <h5>Object labels</h5>
                    <DefinitionTable>
                        <tr>
                            <th>line_table</th>
                            <td>
                                De zone waarbinnen alle factuurregels vallen en de kolom headings.
                            </td>
                        </tr>
                        <tr>
                            <th>line_row</th>
                            <td>De zone waarbinnen 1 factuurregel valt.</td>
                        </tr>
                    </DefinitionTable>

                    <h5>Factuurregel labels</h5>
                    <DefinitionTable>
                        <tr>
                            <th>line_description</th>
                            <td>Omschrijving</td>
                        </tr>
                        <tr>
                            <th>line_quantity</th>
                            <td>Aantal</td>
                        </tr>
                        <tr>
                            <th>line_quantity_unit</th>
                            <td>Eenheid (artikel, uur, kilogram, kubieke meter, etc.)</td>
                        </tr>
                        <tr>
                            <th>line_item_price</th>
                            <td>Eenheidsprijs</td>
                        </tr>
                        <tr>
                            <th>line_amount</th>
                            <td>Totaalbedrag factuurregel</td>
                        </tr>
                        <tr>
                            <th>
                                line_vat_high
                                <br />
                                line_vat_low
                                <br />
                                line_vat_none
                                <br />
                            </th>
                            <td>
                                BTW-percentage of BTW-code
                                <br />
                                Bijvoorbeeld "21%" of "H" is line_vat_high
                            </td>
                        </tr>
                        <tr>
                            <th>line_item_ref</th>
                            <td>Artikelnummer</td>
                        </tr>
                        <tr>
                            <th>line_order_ref</th>
                            <td>Ordernummer</td>
                        </tr>
                        <tr>
                            <th>line_accounting_cost_ref</th>
                            <td>Kostenplaats</td>
                        </tr>
                    </DefinitionTable>
                </Column>
                <Column>
                    <h5>Keyboard shortcuts</h5>
                    <DefinitionTable>
                        <tr>
                            <th>
                                <Key>1</Key> t/m <Key>9</Key>
                            </th>
                            <td>Activeer label 1 - 9</td>
                        </tr>
                        <tr>
                            <th>
                                <Key>←</Key> <Key>→</Key>
                            </th>
                            <td>Activeer vorige / volgende label</td>
                        </tr>
                        <tr>
                            <th>
                                <Key>CTRL</Key>
                            </th>
                            <td>Toon of verberg de annotatie labels in de PDF</td>
                        </tr>
                        <tr>
                            <th>
                                <Key>CTRL</Key> + <Key>S</Key>
                            </th>
                            <td>Document annotaties voltooien (Finish)</td>
                        </tr>
                        <tr>
                            <th>
                                <Key>CTRL</Key> + <Key>Z</Key>
                            </th>
                            <td>Laatste wijziging ongedaan maken</td>
                        </tr>
                    </DefinitionTable>

                    <h5>Interface elementen</h5>
                    <DefinitionTable>
                        <tr>
                            <th>Labels</th>
                            <td>
                                Dit zijn alle beschikbare labels. Klik op een label om deze te
                                activeren. Klik of sleep in de pdf om 1 of meerdere woorden te
                                annoteren
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Key>Finish</Key>
                            </th>
                            <td>Markeer het document als voltooid en ga door naar de volgende</td>
                        </tr>
                        <tr>
                            <th>
                                <Key>Junk</Key>
                            </th>
                            <td>
                                Markeer het document als onbruikbaar. De annotaties worden niet
                                gebruikt voor training. Gebruik dit voor slecht ingescande
                                documenten of onbruikbare facturen
                            </td>
                        </tr>
                        <tr>
                            <th>Annotations</th>
                            <td>Alle annotaties van dit document</td>
                        </tr>
                        <tr>
                            <th>Comments</th>
                            <td>
                                Kan je vullen met uitleg bij rare situaties. Met name handig wanneer
                                je een document markeert als Junk
                            </td>
                        </tr>
                        <tr>
                            <th>Documents</th>
                            <td>Eerste vijf documenten die aan jou zijn toegewezen</td>
                        </tr>
                        <tr>
                            <th>Page classification</th>
                            <td>
                                <div>De classificatie van de PDF-pagina.</div>
                                <div>
                                    Kies alleen <em>Invoice</em> bij pagina's die factuurdata
                                    bevatten. Bij <em>Other</em> mag de pagina geen annotaties
                                    bevatten.
                                </div>
                            </td>
                        </tr>
                    </DefinitionTable>

                    <h5>Algemene werking</h5>
                    <p>Om een factuur te annoteren volg je de volgende stappen:</p>
                    <ol>
                        <li>
                            Kies de <strong>Page classification</strong>. Alleen bij pagina's waar
                            factuurdata op staan kies je <em>Invoice</em>. Wanneer je kiest voor
                            <em>Other</em> dien je eventuele annotaties op die pagina te
                            verwijderen.
                        </li>
                        <li>
                            Activeer een label (bijvoorbeeld <em>issue_date</em>) en klik op de
                            factuurdatum op de factuur. Label alleen de waarde (bijv. "10-11-2022"),
                            niet het type (bijv. "Factuurdatum:"). Wanneer de waarde bestaat uit
                            meerdere woorden (bijv. "10 november 2022" sleep je de annotatie over
                            alle woorden heen, zodat alle woorden als geheel worden geannoteerd.
                        </li>
                        <li>Doe hetzelfde voor alle andere zinnige labels.</li>
                        <li>Herhaal de stappen voor de volgende pagina's.</li>
                        <li>
                            Klik op Finish (of <Key>CTRL</Key> + <Key>S</Key>) om het document te
                            voltooien.
                        </li>
                    </ol>

                    <h5>Huisregels</h5>
                    <ol>
                        <li>
                            Wees consistent. Annoteer zoveel mogelijk data die op een vaste plek
                            staat. Bijvoorbeeld het BTW-nummer van de leverancier rechtsboven of in
                            de footer.
                        </li>
                        <li>
                            Annoteer zoveel mogelijk alleen waardes die uit nabije context ook te
                            herleiden valt. Als er alleen op de factuur "BTW: € 25,50" staat, dan
                            kan je "25,50" het beste annoteren met <em>vat_total_amount</em>, ookal
                            weet je dat Meubelzaak Jansen alleen maar 21% BTW artikelen levert.
                            <br /> Dit voorkomt dat het systeem bij Bloemenzaak Fleurop "BTW: €
                            18,30" ook wordt gezien als 21% BTW.
                        </li>
                        <li>
                            Annoteer zoveel mogelijk bedragen zonder euro-teken. Als bij een
                            negatief bedrag het minteken voor het euro-teken staat (bijv. "-€
                            25,50") dan dien je wel het geheel te annoteren.
                        </li>
                        <li>
                            Eén annotatie per label is in principe voldoende. We hoeven niet alle
                            plekken te weten waar het totaalbedrag staat vermeld. Kies de meest
                            logische plek en annoteer alleen die waarde.
                        </li>
                    </ol>

                    <h5>Rariteiten</h5>
                    <ol>
                        <li>
                            <strong>De annotatie lijnt niet goed uit</strong>
                            <br />
                            Het komt voor dat een annotatie niet netjes een woord omvat, maar iets
                            te kort is, of wat verschoven is. Dit is normaal. Bij het OCR-proces
                            weten we niet altijd de exacte positie van elk woord. Met name bij
                            ingescande documenten zal je dit merken. Wanneer de positie echt niet
                            klopt kan je het document beter als Junk markeren.
                        </li>
                        <li>
                            <strong>De annotatie bevat niet de juiste waarde</strong>
                            <br />
                            Het komt voor dat de annotatie in de <em>Annotations</em> lijst een
                            andere waarde bevat dan op de factuur staat. Als het totaal niet
                            overeenkomt of allerlei gekke tekens bevat dan kan je het woord beter
                            niet annoteren.
                        </li>
                        <li>
                            <strong>De annotatie bevat twee keer hetzelfde woord</strong>
                            <br />
                            In dat geval staat hetzelfde woord ook als een verborgen waarde achter
                            het woord wat je wilt selecteren. Sommige opdrachtgevers passen hun
                            eigen OCR-proces toe wat helaas zorgt voor een onbruikbare PDF. Je kan
                            proberen om alleen 1 woord te selecteren door de selectie in een hoek
                            van het woord te starten. Mocht het verborgen woord altijd meekomen dan
                            je de PDF beter markeren als Junk.
                        </li>
                    </ol>
                </Column>
            </Content>
        </Modal>
    );
};

const Content = styled.div`
    display: flex;
    padding-bottom: 20px;
`;

const Column = styled.div`
    flex: 50%;
    padding: 0 20px;
`;

const DefinitionTable = styled.table`
    width: 100%;

    th,
    td {
        padding: 5px 0;
        border-bottom: 1px solid #eee;
    }
    tr:last-child {
        th,
        td {
            border-bottom: none;
        }
    }

    th {
        text-align: right;
        padding-right: 20px;
        width: 230px;
    }
`;

const Key = styled.div`
    display: inline-block;
    padding: 2px 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
`;
