import React from 'react';
import { SidebarItem } from './common';
import { notification } from '@allenai/varnish';

import { ApiClient, PaperStatus } from '../../services/Client';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

interface ActionsProps {
    sha: string;
    client: ApiClient;
    papers: PaperStatus[];
}

export const Actions = ({ sha, client, papers }: ActionsProps) => {
    const history = useHistory();

    const redirectToNextPage = () => {
        const pendingPapers = papers.filter((p) => !p.finished && p.sha !== sha);

        setTimeout(() => {
            const path = pendingPapers.length > 0 ? '/pdf/' + pendingPapers[0].sha : '/';

            console.log(`Routing to ${path}`);

            history.push(path);
        }, 150);
    };

    const onFinishClicked = () => {
        client.markPdfFinished(sha, true).then(() => {
            notification.success({ message: 'Marked document as Finished!' });

            redirectToNextPage();
        });
    };

    const onJunkClicked = () => {
        client.markPdfJunk(sha, true).then(() => {
            notification.warning({ message: 'Marked document as Junk!' });

            redirectToNextPage();
        });
    };

    return (
        <ActionSidebarItem>
            <FinishButton onClick={() => onFinishClicked()}>Finish</FinishButton>
            <JunkButton onClick={() => onJunkClicked()}>Junk</JunkButton>
        </ActionSidebarItem>
    );
};

const ActionSidebarItem = styled(SidebarItem)`
    min-height: auto;
    overflow-y: unset;
    display: flex;
    padding-left: 6px;
    padding-right: 6px;
`;

const ActionButton = styled.button`
    display: block;
    width: 100%;
    padding: 10px;
    margin: 5px 6px;
    border: none;
    border-radius: 5px;
    flex: 0 50%;

    :hover {
        filter: brightness(110%);
    }

    :active {
        transform: translateY(2px);
    }
`;

const FinishButton = styled(ActionButton)`
    background: #26911d;
`;

const JunkButton = styled(ActionButton)`
    background: #a52f29;
`;
