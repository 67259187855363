import axios, { AxiosInstance } from 'axios';
import { Annotation, PdfAnnotations, PageClassification, RelationGroup } from '../context';

interface UserToken {
    id: string;
    url: string;
}

export interface Token {
    left: number;
    top: number;
    right: number;
    bottom: number;
    text: string;
}

interface Page {
    index: number;
    width: number;
    height: number;
}

export interface PageTokens {
    page: Page;
    tokens: Token[];
}

export enum LabelType {
    TOKEN = 'token',
    OBJECT = 'object',
}

export interface Label {
    text: string;
    color: string;
    type: LabelType;
}

export interface Classification {
    id: string;
    name: string;
}

export interface PaperStatus {
    sha: string;
    name: string;
    annotations: number;
    relations: number;
    finished: boolean;
    junk: boolean;
    comments: string;
    completedAt?: Date;
}

export enum PreparationStatusType {
    PREPARING_STRUCTURE = 'preparing_structure',
    PREPARING_ANNOTATIONS = 'preparing_annotations',
    PREPARING_CLASSIFICATIONS = 'preparing_classifications',
    READY = 'ready',
}

export interface PreparationStatus {
    status: PreparationStatusType;
}

export interface Allocation {
    papers: PaperStatus[];
    hasAssignedPapers: boolean;
}

function DelayPromise(delayTime: number): Promise<void> {
    return new Promise<void>((resolve) => setTimeout(resolve, delayTime));
}

interface RetryOptions {
    attempts?: number;
    delayMs?: number;
    jitter?: number;
}

async function retryOperation<T>(
    operation: (attempt: number) => Promise<T>,
    options: RetryOptions = {}
): Promise<T> {
    const { attempts = 6, delayMs = 10000, jitter = 1 } = options;

    for (let i = 0; i < attempts; i++) {
        const result = await operation(i);
        if (typeof result !== 'undefined') {
            return result;
        }

        const nextDelayMs: number = Math.min(10000, delayMs + delayMs * i * jitter);

        await DelayPromise(nextDelayMs);
    }
    throw new Error('Timeout');
}

export class ApiClient {
    private client: AxiosInstance;

    constructor(readonly baseUrl: string) {
        this.client = axios.create({
            baseURL: baseUrl,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
        });

        this.client.interceptors.response.use(undefined, (error) => {
            if (!Object.hasOwnProperty.call(error, 'response')) {
                throw error;
            }

            const response = error.response;

            if (response && response.status === 401) {
                return this.requestAccessToken().then((token: UserToken) => {
                    localStorage.setItem('accessToken', token.id);

                    window.location.href = token.url;

                    return false;
                });
            }
        });
    }

    getAccessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    public async getAssigned(sha?: string): Promise<Allocation> {
        return this.client
            .get(`/api/invoice-annotations/assigned`, {
                params: { sha },
            })
            .then((r) => r.data);
    }

    public async getClassifications(): Promise<Classification[]> {
        return this.client.get('/api/invoice-annotations/classifications').then((r) => r.data);
    }

    public async getLabels(): Promise<Label[]> {
        return this.client.get(`/api/invoice-annotations/labels`).then((r) => r.data);
    }

    public async getRelations(): Promise<Label[]> {
        return this.client.get(`/api/invoice-annotations/relations`).then((r) => r.data);
    }

    public async getStatus(sha: string): Promise<PreparationStatus> {
        return retryOperation(() => this.client.get(`/api/invoice-annotations/${sha}/status`), {
            attempts: 100,
            delayMs: 1000,
            jitter: 1.5,
        }).then((r) => r.data);
    }

    public getPDFUrl(sha: string): string {
        return `${this.baseUrl}/api/invoice-annotations/${sha}/pdf`;
    }

    public async getTokens(sha: string): Promise<PageTokens[]> {
        return this.client.get(`/api/invoice-annotations/${sha}/tokens`).then((r) => r.data);
    }

    public async getAnnotations(sha: string): Promise<PdfAnnotations> {
        return this.client.get(`/api/invoice-annotations/${sha}/annotations`).then((response) => {
            const data: PdfAnnotations = response.data;
            const annotations = data.annotations.map((a) => Annotation.fromObject(a));
            const relations = data.relations.map((r) => RelationGroup.fromObject(r));
            const classifications = data.classifications.map((c) =>
                PageClassification.fromObject(c)
            );

            return new PdfAnnotations(annotations, relations, classifications);
        });
    }

    public async saveAnnotations(sha: string, pdfAnnotations: PdfAnnotations): Promise<any> {
        return this.client.post(`/api/invoice-annotations/${sha}/annotations`, {
            annotations: pdfAnnotations.annotations,
            relations: pdfAnnotations.relations,
            classifications: pdfAnnotations.classifications.map((p: PageClassification) => {
                return { id: p.classification.id, page: p.page };
            }),
        });
    }

    public async savePdfComment(sha: string, comments: string): Promise<any> {
        return this.client.post(`/api/invoice-annotations/${sha}/comments`, { comments: comments });
    }

    public async markPdfFinished(sha: string, finished: boolean): Promise<any> {
        return this.client.post(`/api/invoice-annotations/${sha}/finished`, { finished: finished });
    }

    public async markPdfJunk(sha: string, junk: boolean): Promise<any> {
        return this.client.post(`/api/invoice-annotations/${sha}/junk`, { junk: junk });
    }

    private async requestAccessToken(): Promise<UserToken> {
        return (
            await this.client.post('/api/tokens', {
                redirect_url: window.location.href,
            })
        ).data as UserToken;
    }
}
