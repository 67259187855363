import { createContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Bounds } from './PDFStore';
import { Classification, Label } from '../services/Client';

export interface TokenId {
    pageIndex: number;
    tokenIndex: number;
}

export class RelationGroup {
    constructor(public sourceIds: string[], public targetIds: string[], public label: Label) {}

    updateForAnnotationDeletion(a: Annotation): RelationGroup | undefined {
        const sourceEmpty = this.sourceIds.length === 0;
        const targetEmpty = this.targetIds.length === 0;

        const newSourceIds = this.sourceIds.filter((id) => id !== a.id);
        const newTargetIds = this.targetIds.filter((id) => id !== a.id);

        const nowSourceEmpty = this.sourceIds.length === 0;
        const nowTargetEmpty = this.targetIds.length === 0;

        // Only target had any annotations, now it has none,
        // so delete.
        if (sourceEmpty && nowTargetEmpty) {
            return undefined;
        }
        // Only source had any annotations, now it has none,
        // so delete.
        if (targetEmpty && nowSourceEmpty) {
            return undefined;
        }
        // Source was not empty, but now it is, so delete.
        if (!sourceEmpty && nowSourceEmpty) {
            return undefined;
        }
        // Target was not empty, but now it is, so delete.
        if (!targetEmpty && nowTargetEmpty) {
            return undefined;
        }

        return new RelationGroup(newSourceIds, newTargetIds, this.label);
    }

    static fromObject(obj: RelationGroup) {
        return new RelationGroup(obj.sourceIds, obj.targetIds, obj.label);
    }
}

export class Annotation {
    public readonly id: string;

    constructor(
        public bounds: Bounds,
        public readonly page: number,
        public readonly label: Label,
        public readonly tokens: TokenId[] | null = null,
        id: string | undefined = undefined
    ) {
        this.id = id || uuidv4();
    }

    width() {
        return this.bounds.right - this.bounds.left;
    }

    height() {
        return this.bounds.bottom - this.bounds.top;
    }

    toString() {
        return this.id;
    }

    /**
     * Returns a deep copy of the provided Annotation with the applied
     * changes.
     */
    update(delta: Partial<Annotation> = {}) {
        return new Annotation(
            delta.bounds ?? Object.assign({}, this.bounds),
            delta.page ?? this.page,
            delta.label ?? Object.assign({}, this.label),
            delta.tokens ?? this.tokens?.map((t) => Object.assign({}, t)),
            this.id
        );
    }

    static fromObject(obj: Annotation) {
        return new Annotation(obj.bounds, obj.page, obj.label, obj.tokens, obj.id);
    }
}

export class PageClassification {
    constructor(public readonly page: number, public readonly classification: Classification) {}

    toString() {
        return this.page;
    }

    static fromObject(obj: PageClassification) {
        return new PageClassification(obj.page, obj.classification);
    }
}

export class PdfAnnotations {
    constructor(
        public readonly annotations: Annotation[],
        public readonly relations: RelationGroup[],
        public readonly classifications: PageClassification[],
        public readonly unsavedChanges: boolean = false
    ) {}

    saved(): PdfAnnotations {
        return new PdfAnnotations(this.annotations, this.relations, this.classifications, false);
    }

    getClassification(page: number): PageClassification | undefined {
        return this.classifications.find((c) => c.page === page);
    }

    withClassification(pageClassification: PageClassification): PdfAnnotations {
        console.log('Updating page classification', pageClassification);

        const existing = this.getClassification(pageClassification.page);

        if (existing && existing.classification.id === pageClassification.classification.id) {
            return this;
        }

        const classifications = this.classifications.filter(
            (pc) => pc.page !== pageClassification.page
        );

        classifications.push(pageClassification);

        console.log('Updated classifications', classifications);

        return new PdfAnnotations(this.annotations, this.relations, classifications, true);
    }

    withNewAnnotation(a: Annotation): PdfAnnotations {
        return new PdfAnnotations(
            this.annotations.concat([a]),
            this.relations,
            this.classifications,
            true
        );
    }

    withNewRelation(r: RelationGroup): PdfAnnotations {
        return new PdfAnnotations(
            this.annotations,
            this.relations.concat([r]),
            this.classifications,
            true
        );
    }

    hasAnnotation(a: Annotation): boolean {
        return (
            this.annotations.find((other: Annotation) => {
                return (
                    a.page === other.page &&
                    a.label.text === other.label.text &&
                    a.bounds.left === other.bounds.left &&
                    a.bounds.right === other.bounds.right &&
                    a.bounds.top === other.bounds.top &&
                    a.bounds.bottom === other.bounds.bottom
                );
            }) !== undefined
        );
    }

    deleteAnnotation(a: Annotation): PdfAnnotations {
        const newAnnotations = this.annotations.filter((ann) => ann.id !== a.id);
        const newRelations = this.relations
            .map((r) => r.updateForAnnotationDeletion(a))
            .filter((r) => r !== undefined);

        return new PdfAnnotations(
            newAnnotations,
            newRelations as RelationGroup[],
            this.classifications,
            true
        );
    }

    undoAnnotation(): PdfAnnotations {
        const popped = this.annotations.pop();
        if (!popped) {
            // No annotations, nothing to update
            return this;
        }
        const newRelations = this.relations
            .map((r) => r.updateForAnnotationDeletion(popped))
            .filter((r) => r !== undefined);

        return new PdfAnnotations(
            this.annotations,
            newRelations as RelationGroup[],
            this.classifications,
            true
        );
    }

    pushAnnotation(a: Annotation): PdfAnnotations {
        this.annotations.push(this.annotations.splice(this.annotations.indexOf(a), 1)[0]);

        return new PdfAnnotations(this.annotations, this.relations, this.classifications, false);
    }

    clear(): PdfAnnotations {
        return new PdfAnnotations([], [], this.classifications, true);
    }
}

interface _AnnotationStore {
    labels: Label[];
    activeLabel?: Label;
    setActiveLabel: (label: Label) => void;

    relationLabels: Label[];
    activeRelationLabel?: Label;
    setActiveRelationLabel: (label: Label) => void;

    classifications: Classification[];

    pdfAnnotations: PdfAnnotations;
    setPdfAnnotations: (t: PdfAnnotations) => void;

    selectedAnnotations: Annotation[];
    setSelectedAnnotations: (t: Annotation[]) => void;

    hideLabels: boolean;
    setHideLabels: (state: boolean) => void;
}

export const AnnotationStore = createContext<_AnnotationStore>({
    pdfAnnotations: new PdfAnnotations([], [], []),
    labels: [],
    activeLabel: undefined,
    setActiveLabel: (_?: Label) => {
        throw new Error('Unimplemented');
    },
    relationLabels: [],
    activeRelationLabel: undefined,
    setActiveRelationLabel: (_?: Label) => {
        throw new Error('Unimplemented');
    },
    classifications: [],
    selectedAnnotations: [],
    setSelectedAnnotations: (_?: Annotation[]) => {
        throw new Error('Unimplemented');
    },
    setPdfAnnotations: (_: PdfAnnotations) => {
        throw new Error('Unimplemented');
    },
    hideLabels: false,
    setHideLabels: (_: boolean) => {
        throw new Error('Unimplemented');
    },
});
