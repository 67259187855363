import React, { MouseEvent, useContext, useState } from 'react';
import styled from 'styled-components';
import { Select } from '@allenai/varnish';
import { AnnotationStore, PageClassification } from '../context';
import { InfoCircleOutlined } from '@ant-design/icons';
import { HelpModal } from './HelpModal';

interface PageToolbarProps {
    pageNumber: number;
}

export const PageToolbar = ({ pageNumber }: PageToolbarProps) => {
    const annotationStore = useContext(AnnotationStore);

    const pageClass = annotationStore.pdfAnnotations.getClassification(pageNumber - 1);

    console.log(`Page ${pageNumber}: ${pageClass?.classification.id}`);

    const [helpModalVisible, setHelpModalVisible] = useState<boolean>(false);

    const onMouseDown = (e: MouseEvent) => {
        e.stopPropagation();
    };

    return (
        <PageToolbarContainer>
            <div>
                <SelectLabel>Page classification</SelectLabel>
                <Select<string>
                    style={{ width: '200px' }}
                    value={pageClass?.classification.id}
                    onMouseDown={onMouseDown}
                    onChange={(id: string) => {
                        const classification = annotationStore.classifications.find(
                            (c) => c.id === id
                        );

                        if (!classification) {
                            return;
                        }

                        annotationStore.setPdfAnnotations(
                            annotationStore.pdfAnnotations.withClassification(
                                new PageClassification(pageNumber - 1, classification)
                            )
                        );
                    }}>
                    {annotationStore.classifications.map((c) => (
                        <Select.Option value={c.id} key={c.id}>
                            {c.name}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <HelpButton onClick={() => setHelpModalVisible(true)}>
                <InfoCircleOutlined style={{ marginRight: '3px' }} /> Help
            </HelpButton>
            <HelpModal visible={helpModalVisible} onOk={() => setHelpModalVisible(false)} />
        </PageToolbarContainer>
    );
};

const SelectLabel = styled.span`
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding-right: 15px;
`;

const PageToolbarContainer = styled.div(
    ({ theme }) => `
    padding: 15px;
    background: ${theme.color.N10};
    display: flex;
    justify-content: space-between;
`
);

const HelpButton = styled.a`
    display: inline-block;
    float: right;
    padding: 10px;
    color: #ccc;
    font-size: 16px;
    border-radius: 5px;

    :hover {
        filter: brightness(110%);
        color: white;
        text-decoration: none;
    }

    :active {
        transform: translateY(1px);
    }
`;
