/**
 * This is the top-level component that defines your UI application.
 *
 * This is an appropriate spot for application wide components and configuration,
 * stuff like application chrome (headers, footers, navigation, etc), routing
 * (what urls go where), etc.
 *
 * @see https://github.com/reactjs/react-router-tutorial/tree/master/lessons
 */

import React, { useEffect, useMemo, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { Result, Spin } from '@allenai/varnish';
import { BrowserRouter, Redirect, Route, useParams } from 'react-router-dom';

import { PDFPage } from './pages';
import { CenterOnPage } from './components';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ApiClient, PaperStatus, PreparationStatus } from './services/Client';

const client = new ApiClient(process.env.REACT_APP_API_BASE_URL ?? '/api');

const RedirectToFirstPaper = () => {
    const [papers, setPapers] = useState<PaperStatus[] | null>(null);

    useEffect(() => {
        client.getAssigned().then((allocation) => setPapers(allocation?.papers || []));
    }, []);

    return useMemo(() => {
        if (!papers) {
            return (
                <CenterOnPage>
                    <Spin size="large" />
                </CenterOnPage>
            );
        }

        /** First available sha */
        const sha = papers.find((p) => !!p.sha)?.sha;

        if (!papers.length || !sha) {
            return (
                <CenterOnPage>
                    <Result icon={<QuestionCircleOutlined />} title="PDFs Not Found" />
                </CenterOnPage>
            );
        }

        return <Redirect to={`/pdf/${sha}`} />;
    }, [papers]);
};

const OpenPDF = () => {
    console.log('Opening pdf ...');

    const { sha } = useParams<{ sha: string }>();

    const [status, setStatus] = useState<PreparationStatus | null>(null);

    useEffect(() => {
        client.getStatus(sha).then((status) => setStatus(status));
    }, [sha]);

    return useMemo(() => {
        if (status?.status !== 'ready') {
            return (
                <CenterOnPage>
                    <Spin size="large" />
                </CenterOnPage>
            );
        }

        return <Redirect to={`/pdf/${sha}`} />;
    }, [status]);
};

const App = () => {
    return (
        <>
            <BrowserRouter>
                <Route path="/" exact component={RedirectToFirstPaper} />
                <Route path="/open/:sha" component={OpenPDF} />
                <Route path="/pdf/:sha">
                    <PDFPage client={client} />
                </Route>
            </BrowserRouter>
            <GlobalStyles />
        </>
    );
};

// Setup the viewport so it takes up all available real-estate.
const GlobalStyles = createGlobalStyle`
    html, body, #root {
        display: flex;
        flex-grow: 1;
    }
`;

export default App;
